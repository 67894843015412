import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Subscribe = (props) => {
    return (
        <section id='subscribe-banner'>
            <Container maxWidth={false} disableGutters>
                <Grid container className='subscribe-banner'>
                    <Grid item xs={12} className='overlay'>
                        <Grid container className="intro-text">
                            <Grid item xs={12} sm={4} md={4}>
                                <Typography variant="h1" className="passion">PASSION</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Typography variant="h1" className="experience">EXPERIENCE</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Typography variant="h1" className="community">COMMUNITY</Typography>
                            </Grid>    
                        </Grid>
                        <Grid container className="subscribtion">
                            <Grid item xs={12} sm={4} md={4} className="social">
                                <Typography variant="h4">Follow Us On</Typography>
                                <ul>
                                    <li><a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a></li>
                                    <li><a href="https://in.linkedin.com/"><i className="fa fa-linkedin" /></a></li>
                                    <li><a href="https://twitter.com/"><i className="fa fa-twitter" /></a></li>
                                    <li><a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a></li>
                                    <li><a href="https://in.pinterest.com/"><i className="fa fa-pinterest" /></a></li>
                                </ul>

                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className="social">
                                <Typography variant="h4">Subscribe to our Newsletter</Typography>
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Email"/>
                                    <span className="input-group-btn">
                                        <button className="btn btn-subscribe" type="button">SUBSCRIBE</button>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} className="social">
                                <Typography variant="h4">Download now</Typography>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <img className="store-button" src="assets/images/app-store-button.png" alt="app-store-button" />
                                        <img className="store-button" src="assets/images/google-store-button.png" alt="app-store-button" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Subscribe;