import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

const AboutUs = (props) => {
    return (
        <section id='about-us'>
            <Container className="container">
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card className="video-card">
                            <video 
                                src="assets/videos/PeCfy_Intro.mp4"
                                frameBorder="0"
                                allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="PeCfy_Intro_HD360"
                                controls >
                            </video>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} spacing={3}>
                        <Card className="video-card">
                            <video
                                src="assets/videos/Sourav-Gangulys-Testimonial.mp4"
                                frameBorder="0"
                                allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Sourav-Gangulys-Testimonial"
                                controls >
                            </video>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12} className='about-us-text'>
                        {/* <Typography variant="h2">
                            About Pecfy
                        </Typography> */}
                        <Typography variant="h4">
                            PeCfy – PeCfy stands for a path in life for people who bring to the world their capacity for action and motion. They are the people who take the initiative and grasp the reins of their destiny.
                        </Typography>
                        <Typography variant="h4">
                            It is also a combination of the words Passion, Experience and Community as the core blocks of the platform being envisaged.
                        </Typography>
                        <Typography variant="h4">
                            It connotes well with our vision to connect people through a common passion, build communities and create superior experiences and memories.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container className="container">
                <Grid container className='about-us-content'>
                    <Grid item xs={4} align="center">
                       <img src="assets/images/PeCfyLogo.jpeg" alt="PeCfyLogo"/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h4">
                            The logo represents coming together of people with passion for their love of life across locations to 
                            connect, build communities and experience events. It also symbolizes collaborative victory.
                        </Typography>
                        <Typography variant="h4" >
                            The color Red signifies passion, Yellow signifies experience from an event and Blue signifies 
                            communities.
                        </Typography>
                        <Typography variant="h4">
                            The font used is simple yet assertive for an easy brand recall and one view readability.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className='about-us-content'>
                   <Grid item xs={8}>
                        <Typography variant="h2" color="secondary">
                            Vision :
                        </Typography>
                        <Typography variant="h4">
                            The vision is to connect people through a common passion, build communities, create superior 
                            memories and experiences.
                        </Typography>
                    </Grid>
                    <Grid item xs={4} align="center">
                       <img  src="assets/images/vision.jpeg" alt="vision"/>
                    </Grid>
                </Grid>
                <Grid container className='about-us-content'>
                    <Grid item xs={4} align="center">
                       <img src="assets/images/mission.jpeg" alt="mission"/>
                    </Grid>
                   <Grid item xs={8}>
                        <Typography variant="h2" color="secondary">
                            Mission :
                        </Typography>
                        <Typography variant="h4">
                            Our mission is to leverage the power of technology to bring back the human bonding that exists 
                            when people with passion for an event interact.
                        </Typography>
                        <Typography variant="h4">
                            We aim to create a seamless platform to enable the journey of communities from a digital to a physical world.
                        </Typography>
                        <Typography variant="h4">
                            Connecting people across geographies to form communities and be closer to the action of an event 
                            that they love and are passionate about is closer to our hearts.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default AboutUs;