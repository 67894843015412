import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const MarketSize = (props) => {
    return (
        <section id='market-size'>
            <Container className="container" disableGutters>
                <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        THE MARKET SIZE
                    </Typography>
                </Grid>
                <Grid className="market-info">
                    <Grid item xs={12}>
                        <img className='market-size-image' src="assets/images/market-size.png" alt="market-size"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Source: Travel & Tourism reports and online publications.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default MarketSize;