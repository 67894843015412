import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Banner = (props) => {
    return (
        <section id='business-banner'>
            <Container maxWidth={false} disableGutters>
                <Grid container className='business-banner'>
                    <Grid item xs={12} className='overlay'>
                        <Grid className='col-md-8 col-md-offset-2 intro-text'>
                            <Typography variant="h4">
                                We believe Passion for a particular sport needs to be just self-driven along with like-minded community for an amazing life experience
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Banner;