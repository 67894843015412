import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const DrivingFactors = (props) => {
    return (
        <section id='driving-factors'>
            <Container className="container" disableGutters>
                <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        THE DRIVING FACTORS
                    </Typography>
                </Grid>
                <Grid>
                    <Container className="container">
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/1.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/2.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/3.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/4.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/5.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container className="driving">
                                    <Grid item xs={4}>
                                        <img className='driving-img' src="assets/images/Driving-Factors/6.jpg" alt="Driving-Factors"/>
                                    </Grid>
                                    <Grid item xs={8} className='driving-content'>
                                        <Typography variant="h4">
                                            Increasing Viewership and Participation of Sports other than Cricket
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Container>
        </section>
    )
}

export default DrivingFactors;