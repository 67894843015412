import React from 'react'
import Web from './layout/Web'
import Banner from './components/Business/Banner'
import MarketSize from './components/Business/MarketSize'
import DrivingFactors from './components/Business/DrivingFactors'
import Advantages from './components/Business/Advantages'

const Business = () => {
	return (
		<div>
			<Web>
				<Banner />
				<MarketSize />
				<DrivingFactors />
				<Advantages />
			</Web>
		</div>
	)
}

export default Business;