import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';

const Banner = (props) => {
    return (
        <section id='header'>
            <Container maxWidth={false} disableGutters>
                <Grid container>
                    <Grid item xs={12}>
                        <img className='index-banner' src="assets/images/banner_1.jpg" alt="banner_1"/>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Banner;