import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const BusinessNeed = (props) => {
    return (
        <section id='business-need'>
            <Container maxWidth={false} disableGutters>
                <Grid container className="business-need">
                    <Grid item xs={12} className="overlay">
                        <Grid className='col-md-8 col-md-offset-2 intro-text'>
                            <Typography variant="h1">
                                THE BUSINESS NEED
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Container maxWidth="md" disableGutters>
                    <Grid container>
                        <Grid item xm={12} sm={6} md={3} className="business-info">
                            <Grid item xm={12} sm={12} md={12} className="content-1-1"> 
                                <img src="assets/images/Business-Need/connect-1.png" alt="connect-1" />
                                <Typography variant="h4">
                                    Connect Enthusiasts
                                </Typography>
                            </Grid>
                            <Grid item xm={12} sm={12} md={12} className="content-1-2"> 
                                <p> People with similar passion for a sport within a location or across geo locations 
                                    to connect and form human bonds on a single platform.
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item xm={12} sm={6} md={3} className="business-info">
                            <Grid item xm={12} sm={12} md={12} className="content-2-1">  
                                <img src="assets/images/Business-Need/community-1.png" alt="community-1" />
                                <Typography variant="h4">
                                    Superior Experience
                                </Typography>
                            </Grid>
                            <Grid item xm={12} sm={12} md={12} className="content-2-2">  
                                <p> Seamless & Unified user experience across multiple service providers for 
                                    participating in sporting events.
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item xm={12} sm={6} md={3} className="business-info">
                            <Grid item xm={12} sm={12} md={12} className="content-3-1">  
                                <img src="assets/images/Business-Need/travel-1.png" alt="travel-1" />
                                <Typography variant="h4">
                                    Sports Tourism
                                </Typography>
                            </Grid>
                            <Grid item xm={12} sm={12} md={12} className="content-3-2">  
                                <p> Encourage passionate sports enthusiasts to travel and attend sporting events across 
                                    the globe.
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item xm={12} sm={6} md={3} className="business-info">
                            <Grid item xm={12} sm={12} md={12} className="content-4-1">  
                                <img src="assets/images/Business-Need/market-1.png" alt="market-1" />
                                <Typography variant="h4">
                                    Marketplace
                                </Typography>
                            </Grid>
                            <Grid item xm={12} sm={12} md={12} className="content-4-2">  
                                <p> Buying and selling of official merchandise and authentic signed sports memorabilia.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </section>
    )
}

export default BusinessNeed;