import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const Advantages = (props) => {
    return (
        <section id='advantages'>
            <Container className="container" disableGutters>
                <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        ADVANTAGES
                    </Typography>
                    <Typography variant="h4">
                        Online PeCfy eco-system featuring
                    </Typography>
                </Grid>
                <Grid>
                    <Grid container className='advantages-info'>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/a-ml-driven-platform.png" alt="AI/ML Driven Platform"/>
                            <Typography variant="h4">
                                AI/ML Driven Platform
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/community.jpeg" alt="Community Builder"/>
                            <Typography variant="h4">
                                Community Builder
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/personal-services.png" alt="Personalised Services"/>
                            <Typography variant="h4">
                                Personalised Services
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/sports-marketplace.png" alt="Sporting Marketplace"/>
                            <Typography variant="h4">
                                Sporting Marketplace
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/experiencing.png" alt="Experiencing Sporting Event"/>
                            <Typography variant="h4">
                                Experiencing Sporting Event
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src="assets/images/Advantages/contextual.png" alt="Real-time Contextual Communication"/>
                            <Typography variant="h4">
                                Real-time Contextual Communication
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Advantages;