import React from 'react'
import Web from './layout/Web'
import Banner from './components/About/Banner' 
import AboutUs from './components/About/AboutUs' 
import Founders from './components/About/Founders' 

const About = () => {
	return (
		<div>
			<Web>
				<Banner />
				<AboutUs />
				<Founders />
			</Web>
		</div>
	)
}

export default About;