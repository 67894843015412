import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Footer = (props) => {
    return (
        <section id='footer'>
            <Container maxWidth={false} disableGutters>
                <Grid container className='text-center'>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            &copy; PeCfy Technologies Pvt. Ltd. All Rights Reserved.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Footer;