import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const Offerings = (props) => {
    return (
        <section id='offering'>
            <Container className="container">
                <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        Our Offerings
                    </Typography>
                </Grid>
                <Grid container spacing={3} className="offering-content">
                    <Grid item xm={12} sm={6} md={4}>
                        <img src="assets/images/it-advisory.png" alt="IT Advisory" />
                        <Grid className="service-desc">
                            <Typography variant="h3">IT Advisory</Typography>
                            <List component="nav">
                                <ListItem>Digital Transformation Strategy & Roadmap</ListItem>
                                <ListItem>Business Process Management</ListItem>
                                <ListItem>Cloud Implementation Strategy & Roadmap</ListItem>
                                <ListItem>Mobile App Strategy & Roadmap</ListItem>
                                <ListItem>Product Lifecycle Management</ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item xm={12} sm={6} md={4}>
                        <img src="assets/images/it-solutions.png" alt="IT Solutions" />
                        <Grid className="service-desc">
                            <Typography variant="h3">IT Solutions</Typography>
                            <List component="nav">
                                <ListItem>Drive EZy</ListItem>
                                <ListItem>StadiaView</ListItem>
                                <ListItem>eiBranch (Interactive Kiosks)</ListItem>
                                <ListItem>VirtualD (Virtual Dealership)</ListItem>
                                <ListItem>PSMS (School Management Solution)</ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item xm={12} sm={6} md={4}>
                        <img src="assets/images/it-services.png" alt="IT Services" />
                        <Grid className="service-desc">
                            <Typography variant="h3">IT Services</Typography>
                            <List component="nav">
                                <ListItem>Custom Web & e-Commerce Development</ListItem>
                                <ListItem>Cloud Deployment & Migration</ListItem>
                                <ListItem>Mobile App & Product Development</ListItem>
                                <ListItem>Data Science & Analytics</ListItem>
                                <ListItem>QuaListItemty Assurance & Testing</ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Offerings;