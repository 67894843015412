import React from "react";
import { Navigation } from "../include/Navigation";
import Footer from "../include/Footer";
import Subscribe from "../include/Subscribe";

const Web = (props) => {
	return (
		<div>
			<Navigation />
				{props.children}
			<Subscribe />
			<Footer />
		</div>
	)
}

export default Web;