import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Content = (props) => {
    return (
        <section id='about'>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item xs={12} className='about-text'>
                        {/* <Typography variant="h2">
                            About Us
                        </Typography> */}
                        <Typography variant="h4">
                            PeCfy is a combination of the words Passion, Experience and Community as the core blocks of the platform being envisaged...
                        </Typography>
                        <Typography variant="h4">
                            ... A platform that provides an end-to-end solution, creating an eco-system for the passionate sports enthusiasts giving them a seamless experience for consuming an event...
                        </Typography>
                        <Typography variant="h4">
                            ... An experience that will change how people embrace going back to the “herd” ways and discover human bonds.
                        </Typography>
                        <Typography variant="h4">
                            The platform will encourage Sports Tourism attracting inbound travelers for hosting cities across the globe.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Content;