import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiFormLabel-root': {
        fontSize: "13px",
      },
    },
  }));

const Address = (props) => {
    const classes = useStyles();
    return (
        <section id='address'>
            <Container maxWidth="lg">
                <Grid container spacing={2} className='address-info'>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row" spacing={3}>
                            {/* <Grid item xs={2}>
                                <IconButton>
                                    <LocationOnIcon className='address-icon' />
                                </IconButton>
                            </Grid> */}
                            <Grid item xs={10} className="address-item">
                                {/* <Typography variant="h4">
                                    Location :
                                </Typography> */}
                                <Typography variant="h6">
                                    Suite # 2, 49 Janak Apartments.
                                </Typography>
                                <Typography variant="h6">
                                    Lane 3, Anand Park Aundh, Pune.
                                </Typography>
                                <Typography variant="h6">
                                    Maharashtra India – 411007
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            {/* <Grid item xs={2}>
                                <IconButton>
                                    <EmailIcon className='address-icon' />
                                </IconButton>
                            </Grid> */}
                            <Grid item xs={12} className="address-item">
                                <Typography variant="h4">
                                    Mail us :
                                </Typography>
                                <Typography variant="h6">
                                    info@pecfy.com.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            {/* <Grid item xs={2}>
                                <IconButton >
                                    <CallIcon className='address-icon'/>
                                </IconButton>
                            </Grid> */}
                            <Grid item xs={12} className="address-item">
                                <Typography variant="h4">
                                    Call Us :
                                </Typography>
                                <Typography variant="h6">
                                    +91 909 670 1116.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="Email"
                                        label="Email"
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="Phone Number"
                                        label="Phone"
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="Subject"
                                        label="Subject"
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Message"
                                        multiline
                                        fullWidth
                                        rows={3}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Address;