import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const WhyChooseUs = (props) => {
    return (
        <section id='why-choose-us'>
            <Container className="container">
                {/* <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        Why PeCfy IT
                    </Typography>
                </Grid> */}
                <Grid container className="why-choose-us-text">
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h4">
                            Our portfolio of Service offerings are aligned to your business goals and range from designing strategy to delivering efficient, innovative and robust solutions. We have around 100+ years of combined experience across various industry lines. We help our customers with –
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <List component="nav">
                            <ListItem>Creative & Innovative Solution Approach</ListItem>
                            <ListItem>Agile Product Implementation & Support</ListItem>
                            <ListItem>Strategic Partnerships & AlListItemances</ListItem>
                            <ListItem>Proactive Customer Support</ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default WhyChooseUs;