import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.css';
import Home from './Home';
import Business from './Business';
import About from './About';
import Services from './Services';
import Contact from './Contact';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Switch >
				<Route path="/" exact component={Home} />
				<Route path="/business" exact component={Business} />
				<Route path="/us" exact component={About} />
				<Route path="/services" exact component={Services} />
				<Route path="/shop" exact component={Home} />
				<Route path="/contact" exact component={Contact} />
			</Switch>
		</BrowserRouter>
	</React.StrictMode>,
	
	document.getElementById('root')
);