import React from 'react'
import Web from './layout/Web'
import Banner from './components/Services/Banner'
import WhyChooseUs from './components/Services/WhyChooseUs'
import Offerings from './components/Services/Offerings'
// import Testimonials from './components/Services/Testimonials'

const Services = () => {
	return (
		<div>
			<Web>
				<Banner />
				<WhyChooseUs />
				<Offerings />
				{/* <Testimonials /> */}
			</Web>
		</div>
	)
}

export default Services;