import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const Founders = (props) => {
    return (
        <section id='founders' className='text-center'>
            <Container className='container' disableGutters>
                <Grid className="col-md-8 col-md-offset-2 section-title">
                    <Typography variant="h2">
                        Founding Members
                    </Typography>
                </Grid>
                <Grid className="col-md-6 col-sm-6 founders">
                    <Grid item className="thumbnail">
                        <img src="assets/images/Founders/Jayant.jpg" alt='Jayant' className='founders-img' />
                        <Grid className='caption'>
                            <Typography variant="h4">
                                JAYANT DABADGHAON
                            </Typography>
                            <Typography color="secondary" variant="h5">
                                BUSINESS PLANNING, STRATEGY & PR
                            </Typography>
                        </Grid>
                        <Typography variant="h6">
                            Co-Founder & a Business leader, who enjoys building new business lines & innovative solutions for the customers. 30 years of Consulting & Global delivery experience.
                        </Typography>
                        <Typography variant="h6">
                            Passionate about Badminton.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className="col-md-6 col-sm-6 founders">
                    <Grid item className="thumbnail">
                        <img src="assets/images/Founders/samudra.jpg" alt='samudra' className='founders-img' />
                        <Grid className='caption'>
                            <Typography variant="h4">
                                SAMUDRA SARKHEL
                            </Typography>
                            <Typography color="secondary" variant="h5">
                                MARKETING & BUSINESS DEVELOPMENT
                            </Typography>
                        </Grid>
                        <Typography variant="h6">
                            Co-Founder and an accomplished leader of more than 20 years of experience in Marketing, Strategic Alliances, Digital Transformation and Disruptive Technologies.
                        </Typography>
                        <Typography variant="h6">
                            Passionate about Formula 1.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Founders;