import React from 'react'
import Web from './layout/Web'
import Banner from './components/Index/Banner'
import Content from './components/Index/Content'
import BusinessNeed from './components/Index/BusinessNeed'

const Home = () => {
	return (
		<div>
			<Web >
				<Banner />
				<Content />
				<BusinessNeed />
			</Web>
		</div>
	)
}

export default Home;