import React from 'react'
import Container from '@material-ui/core/Container';
import Iframe from 'react-iframe'

const Location = (props) => {
    return (
        <section id='location'>
            <Container maxWidth={false} disableGutters>
                <Iframe url="https://maps.google.com/maps?q=Pecfy%2C%20Lane%20Number%203%2C%20Anand%20Park%2C%20Aundh%2C%20Pune%2C%20Maharashtra%2C%20India&t=m&z=16&output=embed&iwloc=near"
                    width="100%"
                    height="450px"
                    display="initial"
                    position="relative"
                />
            </Container>
        </section>
    )
}

export default Location;