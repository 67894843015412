import React from 'react'
import Web from './layout/Web'
import Banner from './components/Contact/Banner'
import Location from './components/Contact/Location'
import Address from './components/Contact/Address'

const Contact = () => {
	return (
		<div>
			<Web>
				<Banner />
				<Location />
				<Address />
			</Web>
		</div>
	)
}

export default Contact;