import React from 'react'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const Banner = (props) => {
    return (
        <section id='services-banner'>
            <div className='services-banner'>
                <div className='overlay'>
                    <Container maxWidth={false} disableGutters>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className='col-md-8 col-md-offset-2 intro-text'>
                                    <Typography variant="h1">
                                        Consulting & IT Services
                                    </Typography>
                                    <span></span>
                                    {/* <Typography variant="body1">
                                        We’re always on the move. Give us a shout.
                                    </Typography> */}
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default Banner;