import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const Navigation = (props) => {
    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
                <div className='navbar-header'>
                    <button type='button' className='navbar-toggle collapsed' data-toggle='collapse'
                        data-target='#bs-example-navbar-collapse-1'
                    >
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>{' '}
                        <span className='icon-bar'></span>{' '}
                        <span className='icon-bar'></span>{' '}
                        <span className='icon-bar'></span>{' '}
                    </button>
                    <img className='img-responsive' src="assets/images/logo.png" alt="pecfy-logo"/>
                    {' '}
                </div>

                <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
                    <ul className='nav navbar-nav navbar-right'>
                        <li>
                            <Link to={'/'} className='page-scroll'>Home</Link>
                        </li>
                        <li>
                            <Link to={'business'} className='page-scroll'>Business</Link>
                        </li>
                        <li>
                            <Link to={'us'} className='page-scroll'>Us</Link>
                        </li>
                        <li>
                            <Link to={'services'} className='page-scroll'>Services</Link>
                        </li>
                        {/* <li>
                            <Link to={'shop'} className='page-scroll'>Shop</Link>
                        </li> */}
                        <li>
                            <Link to={'contact'} className='page-scroll'>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}